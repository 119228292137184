import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

class Azienda extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Azienda" keywords={[`vision care`]} description="Azienda" />
        <div className="container">
          <div className="row">
            <div className={"col"}>
              <h1 className={"page-title"}>Azienda</h1>
              <p>
                Vision Care Italia nasce nel 2003 come spin-off della divisione
                “Vision Care” di Alcon Italia. A capo di questa nuova realtà
                viene ricollocato il management precedente composto da Giovanni
                Montixi, in qualità di Executive Manager e Marcello Pensa (Sales
                Manager). Vision Care Italia si occuperà dell’intero business
                delle soluzioni marchiate Alcon fino al 2011, anno in cui verrà
                completata l’acquisizione di quest’ultima da parte di Novartis.
                Con l’esperienza maturata e un know-how ben riconosciuto nel
                settore, nello stesso anno si concretizza l’accordo con Abbott
                Medical Optics Italia per la distribuzione esclusiva dei
                prodotti della divisione “Corneal”, attuale core-business della
                società.
              </p>

              <p>
                <strong>Giovanni Montixi</strong>
                <br />
                <em>Executive Manager</em>
              </p>

              <p>
                Una lunga esperienza in Alcon Italia dal 1989. Nel 2000, dopo
                aver ricoperto la carica di Area Manager nella divisione
                Surgical, diventa Sales e Marketing Manager di quella Vision
                Care. Nel 2004 è Presidente di Assottica. Nel 2003 fonda Vision
                Care Italia e la dirige in qualità di Manager Esecutivo.
              </p>

              <p>
                <strong>Marcello Pensa</strong>
                <br />
                <em>Sales Manager</em>
              </p>

              <p>
                Da sempre nel settore farmaceutico, entra nel mondo della
                contattologia con Alcon Italia nel 1989 come Area Manager per il
                centro e sud Italia. Nel 2003 in qualità di socio e Sales
                Manager dirige la forza vendita di Vision Care Italia.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Azienda
